// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Login from './components/public/Login';
import Register from './components/public/Register';
import ResetPasswordRequest from './components/public/ResetPasswordRequest';
import CompanyOverviewLayout from './components/private/company/CompanyOverviewLayout';
import CompanyProfile from './components/private/company/CompanyProfile';
import ProfilePage from './components/private/profile/ProfilePage';
import MainLayout from './components/private/MainLayout';
import AlertProvider from './components/Alert';
import CompanySearchResults from "./components/private/company/CompanySearchResults";
import ProtectedRoute from "./components/private/ProtectedRoute";
import BriefingOverview from './components/private/briefing/BriefingOverview';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import GlobalErrorHandler from './GlobalErrorHandler';
import UserManagement from "./components/private/usermanagement/UserManagement";
import BriefingDetails from "./components/private/briefing/BriefingDetails";
import BriefingComponent from "./components/private/briefing/BriefingComponent";
import { persistor } from './store';
import ScoutingGallery from "./components/public/scouting/ScoutingGallery";
import ScrollToTop from "./components/ScrollToTop"; // Importiere den persistor aus deiner Store-Konfiguration

function App() {
    useEffect(() => {
        if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
            // Lösche den persistierten Redux-Store bei einem Page-Refresh
            persistor.purge();
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <AuthProvider>
                    <AlertProvider>
                        <GlobalErrorHandler /> {/* Füge diese Komponente hinzu */}
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/scouting/50d5dd84-660d-42b9-a021-b3c9693c317e" element={<ScoutingGallery    />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/reset-password" element={<ResetPasswordRequest />} />
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <CompanyOverviewLayout />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/companies"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <CompanyOverviewLayout />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/company/:slug"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <CompanyProfile />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/profile"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <ProfilePage />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/user-management"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <UserManagement />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/search-results"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <CompanySearchResults />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/briefings"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <BriefingOverview />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/briefing/new"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <BriefingComponent />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/briefing/:id"
                                element={
                                    <ProtectedRoute>
                                        <MainLayout>
                                            <BriefingDetails />
                                        </MainLayout>
                                    </ProtectedRoute>
                                }
                            />

                        </Routes>
                        <ScrollToTop />

                    </AlertProvider>
                </AuthProvider>
            </Router>
        </ThemeProvider>
    );
}

export default App;
import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Button,
    useMediaQuery,
    useTheme,
    Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/rox-fox.png';
import { useAuth } from '../../AuthContext';
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../../redux/selectors';

const Header = ({ setSearchResults }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
    const [scoutingAnchorEl, setScoutingAnchorEl] = React.useState(null);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { logout, currentUser } = useAuth();
    const roles = useSelector(selectCurrentUserRoles);
    const hasAdminPrivileges = roles.includes('ADMIN') || roles.includes('SUPER_ADMIN');

    // Gemeinsames Menü schließen
    const closeMenus = () => {
        setAnchorEl(null);
        setMobileMenuAnchorEl(null);
        setScoutingAnchorEl(null);
    };

    // Navigation-Funktion, die zusätzlich alle Menüs schließt
    const handleNavigation = (path) => {
        closeMenus();
        navigate(path);
    };

    // Event-Handler
    const openUserMenu = (event) => setAnchorEl(event.currentTarget);
    const openMobileMenu = (event) => setMobileMenuAnchorEl(event.currentTarget);
    const openScoutingMenu = (event) => setScoutingAnchorEl(event.currentTarget);
    const handleProfile = () => handleNavigation('/profile');
    const handleLogout = () => {
        logout();
        handleNavigation('/login');
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.main, margin: 0 }}>
            <Toolbar>
                {isMobile && (
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={openMobileMenu}>
                        <MenuIcon />
                    </IconButton>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <Button color="inherit" onClick={() => handleNavigation('/')} sx={{ padding: 0, minWidth: 'auto' }}>
                        <img src={logo} alt="Logo" style={{ height: 40 }} />
                    </Button>
                    {!isMobile && (
                        <>
                            <Button
                                color="inherit"
                                onClick={openScoutingMenu}
                                sx={{ color: 'white', marginLeft: '16px' }}
                            >
                                Scouting
                            </Button>
                            <Menu
                                anchorEl={scoutingAnchorEl}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                open={Boolean(scoutingAnchorEl)}
                                onClose={closeMenus}
                            >
                                <MenuItem onClick={() => handleNavigation('/companies')}>Companies</MenuItem>
                                <MenuItem onClick={() => handleNavigation('/briefings')}>Briefing</MenuItem>
                                {hasAdminPrivileges && (
                                    <MenuItem onClick={() => handleNavigation('/user-management')}>
                                        User Management
                                    </MenuItem>
                                )}
                            </Menu>
                        </>
                    )}
                </Box>
                {currentUser && (
                    <div>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={openUserMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={Boolean(anchorEl)}
                            onClose={closeMenus}
                        >
                            <MenuItem onClick={handleProfile}>Profile</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                )}
            </Toolbar>
            {isMobile && (
                <Menu
                    anchorEl={mobileMenuAnchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={Boolean(mobileMenuAnchorEl)}
                    onClose={closeMenus}
                >
                    <MenuItem onClick={() => handleNavigation('/companies')}>Companies</MenuItem>
                    <MenuItem onClick={() => handleNavigation('/briefings')}>Briefing</MenuItem>
                    {hasAdminPrivileges && (
                        <MenuItem onClick={() => handleNavigation('/user-management')}>
                            User Management
                        </MenuItem>
                    )}
                </Menu>
            )}
        </AppBar>
    );
};

export default Header;
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useFetchCurrentUserQuery } from "../../api/userApi"; // Dein RTK Query Endpoint
import RoleRequiredModal from "./RoleRequiredModal";

const ProtectedRoute = ({ children }) => {
    // Abrufen des Benutzers und seiner Rollen aus dem Redux-Store
    const { data: user, isLoading, refetch } = useFetchCurrentUserQuery();
    const userRoles = user?.roles || []; // Extrahiere die Rollen aus dem API-Response

    useEffect(() => {
        refetch();
    }, [refetch]);

    if (isLoading) {
        return null;
    }

    if (!user) {
        return <Navigate to="/login" />;
    }

    const userHasNoRoles = userRoles.length === 0;

    return (
        <>
            {userHasNoRoles ? (
                <RoleRequiredModal open={true} email={user.email} uid={user.uid} />
            ) : (
                children
            )}
        </>
    );
};

export default ProtectedRoute;
import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Button, Collapse, Divider } from '@mui/material';
import ReactMarkdown from 'react-markdown';


const EvaluationDisplay = ({ evaluation }) => {
    const [selectedSuperCategory, setSelectedSuperCategory] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);

    // Check if evaluation data exists and has instancesBySuperCategory
    if (!evaluation?.instancesBySuperCategory || evaluation.instancesBySuperCategory.length === 0) {
        return null;
    }

    const handleSuperCategoryChange = (event, newValue) => {
        setSelectedSuperCategory(newValue);
        setSelectedCategory(0); // Reset category selection when super category changes
    };

    const handleCategoryChange = (event, newValue) => {
        setSelectedCategory(newValue);
    };

    // Use instancesBySuperCategory from the API response
    const currentSuperCategory = evaluation.instancesBySuperCategory[selectedSuperCategory];
    // In the API, categories are provided as 'categoryGroups'
    const categories = currentSuperCategory.categoryGroups || [];

    // If no categories, render a message
    if (categories.length === 0) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="body1">Keine Kategorien vorhanden.</Typography>
            </Box>
        );
    }

    // Get current category
    const currentCategory = categories[selectedCategory];

    // Group instances by topic if the 'topics' property doesn't exist
    let topics = [];
    if (currentCategory.topics && currentCategory.topics.length > 0) {
        topics = currentCategory.topics;
    } else if (currentCategory.instances && currentCategory.instances.length > 0) {
        const grouped = currentCategory.instances.reduce((acc, instance) => {
            const key = instance.topic || 'NO_TOPIC';
            if (!acc[key]) {
                acc[key] = {
                    topic: key,
                    topicLabel: instance.topicLabel || key,
                    instances: []
                };
            }
            acc[key].instances.push(instance);
            return acc;
        }, {});
        topics = Object.values(grouped);
    }

    return (
        <Box>
            {/* Display the active SuperCategory label */}
            <Box sx={{ mb: 2 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#424242' }}>
                    {currentSuperCategory.superCategoryLabel}
                </Typography>
            </Box>

            {/* SuperCategories as Tabs with full width and rounded corners */}
            <Tabs
                value={selectedSuperCategory}
                onChange={handleSuperCategoryChange}
                centered
                variant="standard"
                aria-label="Evaluation SuperCategories"
                sx={{ mb: 2, width: '100%', borderRadius: '8px', overflow: 'hidden' }}
            >
                {evaluation.instancesBySuperCategory.map((superCat, index) => (
                    <Tab
                        key={superCat.superCategory}
                        label={superCat.superCategory}
                        id={`evaluation-supercategory-tab-${index}`}
                        aria-controls={`evaluation-supercategory-tabpanel-${index}`}
                        sx={{
                            backgroundColor: superCat.superCategoryColor || '#AEDFF7',
                            color: '#424242',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            borderTopLeftRadius: index === 0 ? '8px' : 0,
                            borderBottomLeftRadius: index === 0 ? '8px' : 0,
                            borderTopRightRadius: index === evaluation.instancesBySuperCategory.length - 1 ? '8px' : 0,
                            borderBottomRightRadius: index === evaluation.instancesBySuperCategory.length - 1 ? '8px' : 0,
                            '&.Mui-selected': {
                                backgroundColor: superCat.superCategoryColor || '#AEDFF7',
                                color: '#000'
                            }
                        }}
                    />
                ))}
            </Tabs>
            {/* Categories as Tabs for selected SuperCategory using the same color */}
            <Tabs
                value={selectedCategory}
                onChange={handleCategoryChange}
                centered
                variant="standard"
                aria-label="Evaluation Categories"
                sx={{ mb: 2, width: '100%', borderRadius: '8px', overflow: 'hidden' }}
            >
                {categories.map((category, index) => (
                    <Tab
                        key={category.category}
                        label={category.categoryLabel}
                        id={`evaluation-category-tab-${index}`}
                        aria-controls={`evaluation-category-tabpanel-${index}`}
                        sx={{
                            backgroundColor: currentSuperCategory.superCategoryColor || '#AEDFF7',
                            color: '#424242',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            borderTopLeftRadius: index === 0 ? '8px' : 0,
                            borderBottomLeftRadius: index === 0 ? '8px' : 0,
                            borderTopRightRadius: index === categories.length - 1 ? '8px' : 0,
                            borderBottomRightRadius: index === categories.length - 1 ? '8px' : 0,
                            '&.Mui-selected': {
                                backgroundColor: currentSuperCategory.superCategoryColor || '#AEDFF7',
                                color: '#000'
                            }
                        }}
                    />
                ))}
            </Tabs>
            {/* Content for selected Category */}
            <Box sx={{ p: 2 }}>
                {/* Table of Contents for topics in this category (without a heading) */}
                {topics.length > 0 && (
                    <Box sx={{ mb: 3, p: 1, backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
                        {topics.map((topic, idx) => (
                            <Box key={idx} sx={{ ml: 2, mb: 0.5 }}>
                                <a
                                    href={`#topic-${topic.topic}-${idx}`}
                                    style={{
                                        textDecoration: 'underline',
                                        color: '#1976d2'
                                    }}
                                >
                                    <Typography variant="body2">{topic.topicLabel}</Typography>
                                </a>
                            </Box>
                        ))}
                    </Box>
                )}
                {/* Evaluation content for each topic */}
                {topics.map((topic, topicIdx) => (
                    <Box key={topicIdx} id={`topic-${topic.topic}-${topicIdx}`} sx={{ mb: 2 }}>
                        {topic.instances && topic.instances.map((instance, instanceIdx) => (
                            <EvaluationInstance
                                key={instanceIdx}
                                instance={instance}
                                catIndex={selectedCategory}
                                idx={instanceIdx}
                            />
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const EvaluationInstance = ({ instance, catIndex, idx }) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(prev => !prev);

    return (
        <Box sx={{ mb: 2, pl: 2 }} id={`topic-${catIndex}-${idx}`}>
            <Typography variant="h6">{instance.topicLabel}</Typography>
            <Box sx={{ mb: 1 }}>
                <ReactMarkdown
                    components={{
                        h2: ({ node, ...props }) => <Typography variant="h2" {...props} />,
                        p: ({ node, ...props }) => <Typography variant="body1" {...props} />,
                        li: ({ node, ...props }) => <Typography variant="body1" component="li" {...props} />,
                    }}
                >
                    {instance.message}
                </ReactMarkdown>
            </Box>
            {instance.citations && instance.citations.length > 0 && (
                <>
                    <Button size="small" onClick={toggleOpen}>
                        {open ? 'Hide Sources' : 'Show Sources'}
                    </Button>
                    <Collapse in={open}>
                        <Box sx={{ mt: 1, backgroundColor: '#f5f5f5', p: 1, borderRadius: '4px' }}>
                            {instance.citations.map((citation, index) => (
                                <Typography key={index} variant="body2">
                                    [{index + 1}]{' '}
                                    <a href={citation} target="_blank" rel="noopener noreferrer">
                                        {citation}
                                    </a>
                                </Typography>
                            ))}
                        </Box>
                    </Collapse>
                </>
            )}
            <Divider sx={{ mt: 2 }} />
        </Box>
    );
};

export default EvaluationDisplay;
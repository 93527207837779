import React, { useState } from 'react';
import { Box, Grid, TextField, Paper } from '@mui/material';
import BriefingEditor from './BriefingEditor';
import briefingDataJson from './briefing.json';
import Briefing from './Briefing';

const BriefingComponent = () => {
    const [briefingData, setBriefingData] = useState(() => {
        const initialBriefing = new Briefing(briefingDataJson);
        if (!initialBriefing.briefingName) {
            initialBriefing.briefingName = `New Briefing - ${new Date().toLocaleString()}`;
        }
        return initialBriefing;
    });

    const [activeStep, setActiveStep] = useState(0);

    const handleBriefingNameChange = (e) => {
        const updatedBriefing = new Briefing({
            ...briefingData.getBriefingData(),
            briefingName: e.target.value,
        });
        setBriefingData(updatedBriefing);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'background.default',
                padding: '16px',
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: '24px',
                    borderRadius: '12px',
                    width: '100%',
                    maxWidth: '800px',
                    marginTop: '16px',
                    overflow: 'hidden',
                }}
            >
                <Grid container spacing={3} direction="column">
                    {/* Wrap the TextField in a Grid item with minWidth: 0 to prevent flex issues */}
                    <Grid item sx={{ minWidth: 0 }}>
                        <TextField
                            fullWidth
                            label="Briefing Name"
                            value={briefingData.briefingName || ''}
                            onChange={handleBriefingNameChange}
                            variant="outlined"
                            sx={{
                                minWidth: 0,
                                width: '100%',
                            }}
                            InputProps={{
                                sx: {
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <BriefingEditor
                            briefingData={briefingData}
                            setBriefingData={setBriefingData}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            questions={briefingData.questions}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default BriefingComponent;
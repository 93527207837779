// src/theme.js
import { createTheme } from '@mui/material';

const themeOptions = {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    margin: 0,
                    padding: 0,
                    boxSizing: 'border-box',
                },
                body: {
                    margin: 0,
                    padding: 0,
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 0,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                },
                icon: {
                    fontSize: '16px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    textTransform: 'none',
                    marginTop: '2px',
                    marginBottom: '2px',
                    padding: '4px',
                    boxShadow: 'none',
                    borderColor: '#183D61',
                    borderWidth: '1.5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(17, 42, 67, 0.5)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(17, 42, 67, 0.5)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#183D61',
                    },
                },
                input: {
                    fontSize: '16px',
                    '&::placeholder': {
                        color: 'rgba(17, 42, 67, 0.5)',
                        fontSize: '16px',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'rgba(17, 42, 67, 0.5)',
                    '&.Mui-checked': {
                        color: '#00A4A4',
                    },
                    '&.MuiCheckbox-indeterminate': {
                        color: '#00A4A4',
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
                '&.Mui-checked': {
                    '& .MuiSvgIcon-root': {
                        color: '#183D61',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    textTransform: 'none',
                    marginTop: '2px',
                    marginBottom: '2px',
                    padding: '12px',
                    boxShadow: 'none',
                    borderColor: '#183D61',
                    borderWidth: '1.5px',
                },
                label: {
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontWeight: 500,
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#183D61',
            light: '#386FA6',
            dark: '#112A43',
            contrastText: '#fff',
        },
        secondary: {
            main: '#21D1D1',
            light: '#A6F5F5',
            dark: '#00A4A4',
            contrastText: '#fff',
        },
        grey: {
            50: '#FDFEFF',
            100: '#F4F7FC',
            200: '#E3E9F1',
            300: '#C1C8D2',
            400: '#97A2B1',
            500: '#6C7888',
            600: '#3C4654',
        },
        background: {
            paper: '#FDFEFF',
            default: '#F7F9FC',
        }
    },
    typography: {
        fontFamily: "Rubik",
        button: {
            fontFamily: "Rubik"
        },
        h1: {
            fontFamily: "Rubik"
        },
        h2: {
            fontFamily: "Rubik"
        },
        h3: {
            fontFamily: "Rubik"
        },
        h4: {
            fontFamily: "Rubik"
        },
        h5: {
            fontFamily: "Rubik"
        },
        h6: {
            fontFamily: "Rubik"
        },
        body1: {
            fontFamily: "Rubik"
        },
        body2: {
            fontFamily: "Rubik"
        },
        p: {
            fontFamily: "Rubik"
        },
    }
};

const theme = createTheme(themeOptions);

export default theme;

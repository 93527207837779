import React, { useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    IconButton,
    CircularProgress,
    Tooltip,
    Tabs,
    Tab,
    Chip
} from '@mui/material';
import {
    Delete,
    Done,
    ContentCopy,
    SmartToy,
    LowPriority,
    PriorityHigh,
    CheckCircle
} from '@mui/icons-material';
import Clear from '@mui/icons-material/Clear'; // Neues Icon für Prompt-Löschung
import { useParams, useNavigate } from 'react-router-dom';
import {
    useFetchBriefingByIdQuery,
    useDeleteBriefingMutation,
    useMarkBriefingAsDoneMutation,
    useDeleteBriefingPromptMutation
} from '../../../api/briefingApi';
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../../../redux/selectors';
import CompanySearchResults from "../company/CompanySearchResults";

const BriefingDetails = () => {
    const { id } = useParams(); // Get briefing ID from the URL params
    const navigate = useNavigate();
    const roles = useSelector(selectCurrentUserRoles);

    const { data: briefing, isLoading } = useFetchBriefingByIdQuery(id);

    const [deleteBriefing] = useDeleteBriefingMutation();
    const [markBriefingAsDone] = useMarkBriefingAsDoneMutation();
    const [deleteBriefingPrompt] = useDeleteBriefingPromptMutation();

    // State zum Toggeln der Vorschau/Full-Ansicht des Prompts
    const [promptExpanded, setPromptExpanded] = useState(false);
    const [selectedTab, setSelectedTab] = useState('longlist');

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleDeleteBriefing = () => {
        deleteBriefing(id).then(() => navigate('/briefings')); // Navigate back after deletion
    };

    // Handle marking as done
    const handleMarkAsDone = () => {
        markBriefingAsDone(id);
    };

    // Copy the prompt content to clipboard
    const handleCopyPrompt = () => {
        navigator.clipboard.writeText(briefing.prompt || '');
    };

    // Handler zum Löschen des Prompts für das aktuelle Briefing
    const handleDeletePrompt = () => {
        if (window.confirm("Are you sure you want to delete the prompt?")) {
            deleteBriefingPrompt(id)
                .unwrap()
                .then(() => {
                    console.info("Prompt deleted successfully");
                })
                .catch((error) => {
                    console.error("Error deleting prompt: ", error);
                });
        }
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    if (!briefing) {
        return <Typography color="error">Briefing not found.</Typography>;
    }

    // Hilfsfunktion: Rendert die Antwort(en) eines Questions-Objekts.
    const renderAnswer = (question) => {
        if (!question.answer) return 'No answer provided';

        // Für multiselect oder hierarchical-multiselect
        if (question.type === 'multiselect' || question.type === 'hierarchical-multiselect') {
            // Falls die Antwort ein Array von Objekten ist, extrahiere den Text
            if (Array.isArray(question.answer) &&
                question.answer.length > 0 &&
                typeof question.answer[0] === 'object' &&
                question.answer[0] !== null) {
                return question.answer.map(ans => ans.text).join(', ');
            } else if (Array.isArray(question.answer)) {
                // Falls es sich um ein Array von primitiven Werten handelt, kann ggf. anhand von metadata.options gesucht werden
                const findOptionText = (options, id) => {
                    for (let option of options) {
                        if (option.id === id) return option.text;
                        if (option.subregions) {
                            const subText = findOptionText(option.subregions, id);
                            if (subText) return subText;
                        }
                    }
                    return id;
                };
                return question.answer.map(id => findOptionText(question.metadata.options, id)).join(', ');
            }
        }

        // Für text-Fragen mit Keyword-Metadaten: Rendere jeden Eintrag als Chip
        if (question.type === 'text' && question.metadata?.keyword) {
            return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {Array.isArray(question.answer)
                        ? question.answer.map((keyword, index) => (
                            <Chip
                                key={index}
                                label={typeof keyword === 'object' ? keyword.text : keyword}
                                size="small"
                            />
                        ))
                        : <Chip label={question.answer} size="small" />
                    }
                </Box>
            );
        }

        // Falls die Antwort ein Array ist
        if (Array.isArray(question.answer)) {
            // Falls es ein Array von Objekten ist, extrahiere den Text
            if (question.answer.length > 0 &&
                typeof question.answer[0] === 'object' &&
                question.answer[0] !== null) {
                return question.answer.map(ans => ans.text).join(', ');
            }
            return question.answer.join(', ');
        }

        // Andernfalls gebe die Antwort direkt als Text zurück
        return question.answer;
    };

    // Funktion zur Bestimmung des Icons für Answer Quality
    const getQualityIcon = (quality) => {
        switch (quality) {
            case 'low':
                return <LowPriority sx={{ color: 'red' }} />;
            case 'medium':
                return <PriorityHigh sx={{ color: 'orange' }} />;
            case 'high':
                return <CheckCircle sx={{ color: 'green' }} />;
            default:
                return null;
        }
    };

    return (
        <Box sx={{ padding: '16px', position: 'relative' }}>
            {/* Header Section */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {briefing.name}
                </Typography>

                {/* Actions (Delete Briefing and Mark as Done) */}
                <Box>
                    {/* Delete Briefing Icon */}
                    <IconButton sx={{ color: 'error.main' }} onClick={handleDeleteBriefing}>
                        <Delete />
                    </IconButton>

                    {/* Mark as Done Icon (only for authorized users) */}
                    {(roles.includes('ADMIN') || roles.includes('SUPER_ADMIN') || roles.includes('BIZ_DEV')) && (
                        <IconButton sx={{ color: 'success.main' }} onClick={handleMarkAsDone}>
                            <Done />
                        </IconButton>
                    )}
                </Box>
            </Box>

            <Grid container spacing={4} sx={{ marginTop: '24px' }}>
                {/* Questions and Answers Section */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" sx={{ marginBottom: '16px', fontWeight: 'bold' }}>
                        Questions and Answers
                    </Typography>
                    {briefing.document?.questions.map((q) => (
                        <Box
                            key={q.id}
                            sx={{
                                marginBottom: '16px',
                                padding: '16px',
                                backgroundColor: '#f9f9f9',
                                borderRadius: '8px',
                                boxShadow: 1
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    {q.question}
                                </Typography>

                                {q.ai_feedback_needed && (
                                    <Tooltip title="This question was answered with AI assistance" placement="left">
                                        <SmartToy sx={{ color: 'blue' }} />
                                    </Tooltip>
                                )}
                            </Box>

                            <Typography
                                variant="body2"
                                sx={{ marginTop: '8px', fontSize: '0.95rem', color: '#555' }}
                            >
                                {renderAnswer(q)}
                            </Typography>

                            {/* Only show answer quality if AI feedback is needed */}
                            {q.ai_feedback_needed && (
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
                                    {getQualityIcon(q.rating.answerQuality)}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            marginLeft: '8px',
                                            fontWeight: 'bold',
                                            color: getQualityIcon(q.rating.answerQuality)?.props.sx.color
                                        }}
                                    >
                                        Answer Quality: {q.rating.answerQuality.toUpperCase()}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ))}
                </Grid>

                {/* Prompt and Company Search Section */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h5" sx={{ marginBottom: '16px', fontWeight: 'bold' }}>
                            Generated Search Prompt
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Copy to clipboard" placement="left">
                                <IconButton onClick={handleCopyPrompt}>
                                    <ContentCopy />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete prompt" placement="left">
                                <IconButton onClick={handleDeletePrompt} sx={{ ml: 1 }}>
                                    <Clear />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    {/* Prompt Preview / Full Content Box */}
                    <Box
                        sx={{
                            border: '1px solid #e0e0e0',
                            borderRadius: 1,
                            p: 2,
                            backgroundColor: '#f9f9f9',
                            maxHeight: promptExpanded ? 'none' : 50,
                            overflow: 'hidden'
                        }}
                    >
                        <Typography variant="body1">
                            {briefing.prompt || ''}
                        </Typography>
                    </Box>

                    {/* Toggle für mehr/weniger anzeigen */}
                    <Typography
                        variant="body2"
                        onClick={() => setPromptExpanded(!promptExpanded)}
                        sx={{ cursor: 'pointer', color: 'primary.main', mt: 1 }}
                    >
                        {promptExpanded ? 'Weniger anzeigen' : 'Mehr anzeigen'}
                    </Typography>

                    {/* Box für CompanySearchResults mit Tabs */}
                    <Box sx={{ mt: 4 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                aria-label="Company Search Tabs"
                            >
                                <Tab label="Longlist" value="longlist" />
                                <Tab label="Shortlist" value="shortlist" />
                            </Tabs>
                        </Box>
                        {selectedTab === 'longlist' && (
                            <Box sx={{ p: 2 }}>
                                <CompanySearchResults prompt={briefing.prompt || ''} />
                            </Box>
                        )}
                        {selectedTab === 'shortlist' && (
                            <Box sx={{ p: 2 }}>
                                <Typography variant="body1">Not yet implemented</Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>

            {/* Status Information placed at the bottom right */}
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '16px',
                    right: '16px',
                    textAlign: 'right',
                    fontSize: '0.9rem'
                }}
            >
                <Typography variant="body2">
                    <strong>Status:</strong> {briefing.status}
                </Typography>
                <Typography variant="body2">
                    <strong>Created by:</strong> {briefing.createdBy.email}
                </Typography>
                <Typography variant="body2">
                    <strong>Created on:</strong> {new Date(briefing.createdAt).toLocaleDateString()}
                </Typography>
            </Box>
        </Box>
    );
};

export default BriefingDetails;
import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useAuth } from "../../AuthContext";
import { useFetchCurrentUserQuery } from "../../api/userApi";

const RoleRequiredModal = ({ open }) => {
    const { logout } = useAuth();
    const { data: currentUser } = useFetchCurrentUserQuery();

    return (
        <Modal open={open} aria-labelledby="role-required-modal">
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    textAlign: "center",
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Access Restricted
                </Typography>
                <Typography variant="body1" paragraph>
                    Your account is not yet activated. Please contact <b>scouting@roxhealth.com</b> to gain access.
                </Typography>
                {currentUser && (
                    <>
                        <Typography variant="body2" color="textSecondary">
                            Logged in Email: <b>{currentUser.email}</b>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            User ID (UID): <b>{currentUser.uid}</b>
                        </Typography>
                    </>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                    onClick={logout}
                >
                    Logout
                </Button>
            </Box>
        </Modal>
    );
};

export default RoleRequiredModal;
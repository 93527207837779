import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import {Delete, Done, BusinessCenter} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../../../redux/selectors';

const BriefingCard = ({
                          briefing,
                          handleEditBriefing,
                          handleDeleteBriefing,
                          handleMarkAsDone,
                          handleMarkForBizDev,
                          isCompleted
                      }) => {
    const roles = useSelector(selectCurrentUserRoles);

    return (
        <Box
            sx={{
                padding: '16px',
                backgroundColor: isCompleted ? 'success.light' : 'primary.main',
                color: 'white',
                borderRadius: '8px',
                position: 'relative',
            }}
        >
            {/* Delete Icon */}
            <IconButton
                sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}
                onClick={() => handleDeleteBriefing(briefing.id)}
            >
                <Delete sx={{ color: 'white' }}/>
            </IconButton>

            {/* Mark as Done Icon (nur wenn nicht completed und autorisiert) */}
            {!isCompleted && (roles.includes('ADMIN') || roles.includes('SUPER_ADMIN') || roles.includes('BIZ_DEV')) && (
                <IconButton
                    sx={{ position: 'absolute', top: 8, right: 40, color: 'white' }}
                    onClick={() => handleMarkAsDone(briefing.id)}
                >
                    <Done sx={{ color: 'white' }}/>
                </IconButton>
            )}

            {/* Mark for Business Development Icon (nur wenn der Prop vorhanden ist) */}
            {handleMarkForBizDev && (
                <IconButton
                    sx={{ position: 'absolute', top: 8, right: 72, color: 'white' }}
                    onClick={() => handleMarkForBizDev(briefing.id)}
                    title="Mark for Business Development"
                >
                    <BusinessCenter sx={{ color: 'white' }} />
                </IconButton>
            )}

            <Typography variant="body1">{briefing.name}</Typography>
            <Typography variant="body2">
                Created on {new Date(briefing.createdAt).toLocaleDateString()}
            </Typography>
            <Typography variant="body2">
                Created by {briefing.createdBy.email}
            </Typography>
            {isCompleted ? (
                <Typography variant="body2">
                    Completed on {new Date(briefing.completedAt).toLocaleDateString()}
                </Typography>
            ) : (
                <Typography variant="body2">
                    Status: {briefing.status}
                </Typography>
            )}
            <Button
                variant="contained"
                color="secondary"
                onClick={() => handleEditBriefing(briefing.id)}
                sx={{ marginTop: '8px' }}
            >
                View Briefing
            </Button>
        </Box>
    );
};

export default BriefingCard;
import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import CompanyItem from './CompanyItem';
import { useGetCompaniesByPromptQuery } from '../../../api/companyApi';

const CompanySearchResults = ({ prompt }) => {
    const { data: results, isLoading, error } = useGetCompaniesByPromptQuery(prompt, {
        skip: !prompt,
    });

    if (!prompt) {
        return <Typography variant="body1">No search prompt given... please refresh page as this cane take up to one minute.</Typography>;
    }

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        console.error('Error fetching companies', error);
        return <Typography color="error">Fehler beim Laden der Firmen.</Typography>;
    }

    if (!results) {
        return <Typography>Keine Ergebnisse gefunden.</Typography>;
    }

    const { companies } = results;

    return (
        <Box sx={{ mt: 2 }}>
            {/* Überschrift mit Anzahl der gefundenen Firmen, wobei die Zahl in einem Chip dargestellt wird */}
            <Typography variant="h6" sx={{ mb: 2 }}>
                Found <Chip label={companies.length} sx={{ mx: 1 }} /> matching Companies
            </Typography>

            {companies.map((company) => (
                <Link
                    key={company.id}
                    to={`/company/${company.Slug}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    <Box sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 2 }}>
                        <CompanyItem company={company} />
                    </Box>
                </Link>
            ))}
        </Box>
    );
};

export default CompanySearchResults;
// src/components/public/Register.js
import React, { useState } from 'react';
import { Typography, Box, Grid, Paper, Link, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import BackgroundContainer from './BackgroundContainer';
import LogoAvatar from './LogoAvatar';
import RegisterForm from './RegisterForm';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const { register } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            await register(email, password);
            // Zeige das Modal nach erfolgreicher Registrierung
            setModalOpen(true);
            // Nach 2 Sekunden Modal schließen und zur Login-Seite navigieren
            setTimeout(() => {
                setModalOpen(false);
                navigate('/login');
            }, 2000);
        } catch (err) {
            setError('Registration failed. Please try again.');
        }
    };

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <BackgroundContainer />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <LogoAvatar />
                    <Typography component="h1" variant="h5">
                        Register
                    </Typography>
                    <RegisterForm
                        handleSubmit={handleSubmit}
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        error={error}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2">
                            Already registered?{' '}
                            <Link onClick={() => navigate('/login')} sx={{ cursor: 'pointer' }}>
                                Login here
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Grid>

            {/* Modal, das bei erfolgreicher Registrierung angezeigt wird */}
            <Modal open={modalOpen} onClose={() => {}}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        outline: 'none',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Registration successful
                    </Typography>
                    <Typography variant="body1">
                        Please check your email for verification.
                    </Typography>
                </Box>
            </Modal>
        </Grid>
    );
};

export default Register;
import React, { useMemo, useCallback, lazy, Suspense, useState } from 'react';
import BriefingStepNavigator from './BriefingStepNavigator';
import StepComponent from './StepComponent';
import Briefing from "./Briefing";
import { useCreateBriefingMutation } from "../../../api/briefingApi";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const ChatInterface = lazy(() => import('./ChatInterface'));

const BriefingEditor = ({ briefingData, setBriefingData, activeStep, setActiveStep, questions }) => {
    const [createBriefing] = useCreateBriefingMutation();
    const [messages, setMessages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [briefingComplete, setBriefingComplete] = useState(false);
    const navigate = useNavigate();

    // Callback für Antworten (bleibt stabil)
    const handleAnswerChange = useCallback((questionId, answer) => {
        setBriefingData(prevData => {
            const updatedQuestions = prevData.questions.map(q =>
                q.id === questionId ? { ...q, answer } : q
            );

            return new Briefing({
                ...prevData.getBriefingData(),
                questions: updatedQuestions
            });
        });
    }, [setBriefingData]);

    // Filtere Fragen für alle Schritte
    const filteredQuestions = useMemo(() => {
        return questions.filter(q => q.step !== "step3" || q.answer);
    }, [questions]);

    const handleBriefingCompletion = useCallback(async (receivedBriefing) => {
        if (!receivedBriefing) return;

        try {
            const finalBriefing = new Briefing({
                ...receivedBriefing,
                briefingName: briefingData.briefingName
            });

            const documentData = mapBriefingToDocument(finalBriefing);

            const payload = {
                name: briefingData.briefingName,
                document: documentData,
            };

            await createBriefing(payload).unwrap();

            setMessages((prevMessages) => [
                ...prevMessages,
                { type: 'info', content: '✅ Briefing saved successfully.' }
            ]);

            // Setze den globalen Zustand, dass das Briefing fertig ist.
            setBriefingComplete(true);
            setIsModalOpen(true);
        } catch (error) {
            setMessages((prevMessages) => [
                ...prevMessages,
                { type: 'error', content: '⚠️ Failed to save briefing.' },
            ]);
            console.error('Error saving briefing:', error);
        }
    }, [briefingData, createBriefing]);

    // Stablen onMessage-Callback definieren – ohne Abhängigkeit von messages,
    // da der funktionale State-Updater genutzt wird.
    const handleOnMessage = useCallback((message) => {
        setMessages(prev => [...prev, message]);
    }, []);

    // Statische Props für ChatInterface; diese ändern sich nicht, wenn Nachrichten
    // hinzugefügt werden
    const chatInterfaceStaticProps = useMemo(() => ({
        handleAnswerChange,
        onMessage: handleOnMessage,
        onBriefingCompleted: handleBriefingCompletion,
        questions: briefingData.questions, // falls in ChatInterface benötigt, sonst weglassen
    }), [handleAnswerChange, handleOnMessage, handleBriefingCompletion, briefingData.questions]);

    // Memoisiere die Fragen für den Chat‑Step, sodass ein neues Array nur erstellt wird,
    // wenn sich filteredQuestions ändert
    const chatStepQuestions = useMemo(() => {
        return filteredQuestions.filter(q => q.step === "step3");
    }, [filteredQuestions]);

    // Definiere die Schritte – für den Chat nutzen wir nun die memoisierten chatStepQuestions.
    const steps = useMemo(() => [
        {
            label: 'Key Parameters',
            component: StepComponent,
            props: { step: "step1", questions: filteredQuestions.filter(q => q.step === "step1") },
        },
        {
            label: 'Scope',
            component: StepComponent,
            props: { step: "step2", questions: filteredQuestions.filter(q => q.step === "step2") },
        },
        {
            label: 'Refine & Expand',
            component: ChatInterface,
            props: { step: "step3", questions: chatStepQuestions, isBriefingComplete: briefingComplete  },
        },
    ], [filteredQuestions, chatStepQuestions, briefingComplete]);

    const mapBriefingToDocument = (briefing) => ({
        questions: briefing.questions.map((q) => ({
            id: q.id,
            question: q.question,
            answer: Array.isArray(q.answer) ? q.answer : [q.answer],
            ai_feedback_needed: q.ai_feedback_needed || false,
            rating: {
                answerQuality: q.answerQuality || 'not_specified',
            },
        })),
    });

    // Beim Rendern des aktiven Schritts werden die statischen ChatInterface-Props mit
    // den dynamischen (messages) kombiniert. Dadurch ändert sich die Identität der Chat-Komponente
    // nicht, wenn nur messages neu sind.
    const activeStepComponent = useMemo(() => {
        const ActiveComponent = steps[activeStep].component;
        const stepProps = steps[activeStep].props || {};
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <ActiveComponent
                    {...stepProps}
                    {...chatInterfaceStaticProps}
                    messages={messages}  // dynamische Nachrichten separat übergeben
                    briefingData={briefingData}
                    handleAnswerChange={handleAnswerChange}
                />
            </Suspense>
        );
    }, [activeStep, steps, chatInterfaceStaticProps, messages, briefingData, handleAnswerChange]);

    return (
        <>
            <BriefingStepNavigator
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
            >
                {activeStepComponent}
            </BriefingStepNavigator>

            {/* Modal für den Abschluss des Briefings */}
            <Modal
                open={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    navigate('/briefings');
                }}
                aria-labelledby="briefing-completion-modal-title"
                aria-describedby="briefing-completion-modal-description"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        backgroundColor: 'white',
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: 24,
                        textAlign: 'center',
                        width: 400,
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setIsModalOpen(false);
                            navigate('/briefings');
                        }}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography id="briefing-completion-modal-title" variant="h6" component="h2" gutterBottom>
                        🎉 Thank you!
                    </Typography>
                    <Typography id="briefing-completion-modal-description" variant="body1">
                        Thank you for enriching a briefing with our Agentic chatbot. It will now be shared with Business Development.
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};

export default React.memo(BriefingEditor);
import React, { useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Checkbox,
    FormControlLabel,
    Chip,
    InputAdornment,
    Box,
    ListItemText
} from '@mui/material';

const QuestionInput = ({ question, value, onChange }) => {
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);

    const handleChange = (event) => {
        const newValue = question.type === 'multiselect' ? event.target.value : event.target.value;
        onChange(question.id, newValue);
    };

    // Angepasste handleKeyDown-Funktion: trennt Eingabe anhand von Komma oder Semikolon
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim()) {
            event.preventDefault();
            // Aufspalten anhand von Komma oder Semikolon und Leerräumen entfernen
            const tokens = inputValue.split(/[,;]+/).map(token => token.trim()).filter(token => token !== '');
            const newValue = Array.isArray(value) ? [...value, ...tokens] : tokens;
            onChange(question.id, newValue);
            setInputValue('');
        }
    };

    const handleDelete = (item) => {
        const newValue = Array.isArray(value) ? value.filter(val => val !== item) : [];
        onChange(question.id, newValue);
    };

    const toggleRecursiveSelection = (option, isSelected) => {
        let newValue = [...value];

        const toggleSubregions = (opt, select) => {
            if (select) {
                if (!newValue.includes(opt.id)) {
                    newValue.push(opt.id);
                }
            } else {
                newValue = newValue.filter(val => val !== opt.id);
            }
            if (opt.subregions) {
                opt.subregions.forEach(subregion => toggleSubregions(subregion, select));
            }
        };

        toggleSubregions(option, isSelected);
        onChange(question.id, newValue);
    };

    const handleToggle = (option) => {
        const isSelected = !value.includes(option.id);
        toggleRecursiveSelection(option, isSelected);
    };

    const renderMenuItems = (options, level = 0) => {
        return options.flatMap((option) => [
            <MenuItem
                key={option.id}
                value={option.id}
                style={{ paddingLeft: `${level * 20}px` }}
            >
                <Checkbox
                    checked={value.includes(option.id)}
                    onClick={() => handleToggle(option)}
                />
                <ListItemText primary={option.text} />
            </MenuItem>,
            ...(option.subregions ? renderMenuItems(option.subregions, level + 1) : [])
        ]);
    };

    const renderSelectedValues = (selected) => {
        return selected
            .map(val => {
                const option = findOptionById(question.metadata.options, val);
                return option ? option.text : val;
            })
            .join(', ');
    };

    const findOptionById = (options, id) => {
        for (let option of options) {
            if (option.id === id) return option;
            if (option.subregions) {
                const found = findOptionById(option.subregions, id);
                if (found) return found;
            }
        }
        return null;
    };

    // Common style to prevent text from overflowing its container.
    const commonInputStyle = {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
        maxWidth: '100%'
    };

    return (
        <FormControl key={question.id} fullWidth sx={{ marginTop: '16px' }}>
            <InputLabel id={`${question.id}-label`} shrink>
                {question.question}
            </InputLabel>
            {question.type === 'multiselect' ? (
                <Select
                    id={question.id}
                    name={question.id}
                    value={Array.isArray(value) ? value : []}
                    onChange={handleChange}
                    multiple
                    sx={{
                        marginTop: '8px',
                        // Force the displayed value not to expand the container:
                        '& .MuiSelect-select': {
                            ...commonInputStyle
                        }
                    }}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((val) => (
                                <Chip
                                    key={val}
                                    label={question.metadata.options.find(opt => opt.id === val)?.text}
                                />
                            ))}
                        </Box>
                    )}
                >
                    {question.metadata.options.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.text}
                        </MenuItem>
                    ))}
                </Select>
            ) : question.type === 'text' && question.metadata?.keyword ? (
                <TextField
                    id={question.id}
                    name={question.id}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    variant="outlined"
                    sx={{ marginTop: '8px' }}
                    placeholder="Add keywords (comma or semicolon separated) and press Enter"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {Array.isArray(value) && value.map((val) => (
                                        <Chip
                                            key={val}
                                            label={val}
                                            onDelete={() => handleDelete(val)}
                                        />
                                    ))}
                                </Box>
                            </InputAdornment>
                        ),
                        inputProps: {
                            style: commonInputStyle
                        }
                    }}
                />
            ) : question.type === 'text' ? (
                <TextField
                    id={question.id}
                    name={question.id}
                    value={value || ''}
                    onChange={(e) => onChange(question.id, e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ marginTop: '8px' }}
                    InputProps={{
                        inputProps: {
                            style: commonInputStyle
                        }
                    }}
                />
            ) : question.type === 'hierarchical-multiselect' ? (
                <Select
                    multiple
                    value={value}
                    sx={{
                        marginTop: '8px',
                        '& .MuiSelect-select': {
                            ...commonInputStyle
                        }
                    }}
                    onChange={() => {}}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {renderSelectedValues(selected)}
                        </Box>
                    )}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    open={open}
                >
                    {renderMenuItems(question.metadata.options)}
                </Select>
            ) : (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={Boolean(value)}
                            onChange={(e) => onChange(question.id, e.target.checked)}
                            name={question.id}
                        />
                    }
                    label={question.question}
                />
            )}
        </FormControl>
    );
};

export default QuestionInput;
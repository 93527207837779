import React, { useEffect, useState, useCallback } from 'react';
import { Fab, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false);

    // Memoisierte Funktion, die nur neu erzeugt wird, wenn sich `visible` ändert
    const checkScrollTop = useCallback(() => {
        if (!visible && window.pageYOffset > 300) {
            setVisible(true);
        } else if (visible && window.pageYOffset <= 300) {
            setVisible(false);
        }
    }, [visible]);

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, [checkScrollTop]);

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Zoom in={visible}>
            <Fab
                color="primary"
                size="small"
                onClick={handleClick}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
                aria-label="scroll back to top"
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
};

export default ScrollToTop;
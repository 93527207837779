import React from 'react';
import { useParams } from 'react-router-dom';
import {Box, Typography, Avatar, Link, Paper, Divider, IconButton} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EvaluationDisplay from './EvaluationDisplay';
import {useGetCompanyBySlugQuery, useLazyGetCompanyPdfQuery} from '../../../api/companyApi';
import MoonshotLabel from '../../../images/Moonshot_Label.png';
import DTXLabel from '../../../images/DTX_Label.png';

const formatURL = (url) => {
    return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
};

const CompanyProfile = () => {
    const { slug } = useParams();
    const { data: company, error, isLoading } = useGetCompanyBySlugQuery(slug);

    // Lazy-Query für PDF:
    const [fetchPdf, { isFetching: isPdfLoading }] = useLazyGetCompanyPdfQuery();

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (error || !company) {
        return <Typography>Error loading company data.</Typography>;
    }

    // Construct the logo URL using Google's favicon service with the company URL
    const logoUrl = `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${formatURL(company.websiteUrl)}&size=128`;

    // Event-Handler zum Download:
    const handleDownloadPdf = async () => {
        try {
            // 1) PDF anfordern
            const pdfBlob = await fetchPdf(company.slug).unwrap();

            // 2) Aus dem Blob einen temporären Download-Link erzeugen
            const blobUrl = URL.createObjectURL(pdfBlob);

            // 3) Per <a> herunterladen
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${company.slug}-report.pdf`; // Dateiname
            document.body.appendChild(link);
            link.click();

            // 4) Aufräumen
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
        } catch (err) {
            console.error('Failed to download PDF:', err);
        }
    };

    return (
        <Box sx={{ backgroundColor: '#F7F9FC', minHeight: '100vh' }}>
            <Box sx={{ width: '100%', backgroundColor: '#112A43', position: 'relative', paddingBottom: '24px' }}>
                <Box sx={{ position: 'absolute', bottom: '4px', left: 0, right: 0, height: '45px', backgroundColor: '#011529' }} />
                <Box sx={{ maxWidth: '1024px', margin: '0 auto', padding: '0 16px', color: '#ffffff', position: 'relative' }}>
                    <Box display="flex" alignItems="center" mb={2} sx={{ position: 'relative', zIndex: 1 }}>
                        <Avatar
                            src={logoUrl}
                            alt={company.name}
                            sx={{ width: 128, height: 128, mr: 3, borderRadius: 2 }}
                        />
                        <Box>
                            <Typography variant="h4" gutterBottom>
                                {company.name}
                            </Typography>
                            <Box display="flex" alignItems="center" mb={1}>
                                <LocationOnIcon sx={{ mr: 1 }} />
                                <Typography variant="body1" sx={{ mr: 2 }}>
                                    {company.location || 'No Location Provided'}
                                </Typography>
                                {company.Is_Moonshot && (
                                    <Box component="img" src={MoonshotLabel} alt="Moonshot" sx={{ height: 24, mr: 1 }} />
                                )}
                                {company.Is_Dtx && (
                                    <Box component="img" src={DTXLabel} alt="DTX" sx={{ height: 24 }} />
                                )}
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Link
                                    href={formatURL(company.websiteUrl)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        color: '#90C0F0',
                                        textDecoration: 'none',
                                        backgroundColor: '#011529',
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                        mr: 2,
                                    }}
                                >
                                    {company.websiteUrl}
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ padding: 3, maxWidth: '1024px', margin: '0 auto' }}>
                <Paper
                    sx={{
                        padding: 3,
                        mb: 3,
                        backgroundColor: '#ffffff',
                        border: '0.5px solid #DCDCDC',
                        borderRadius: '10px',
                        boxShadow: 'none',
                    }}
                >
                    <Typography variant="h6" gutterBottom sx={{ color: '#386FA6' }}>
                        Description
                    </Typography>
                    <Divider sx={{ borderColor: '#DCDCDC', marginBottom: 2 }} />
                    <Typography variant="body1" gutterBottom>
                        {company.description || 'No Description Provided'}
                    </Typography>
                    {company.foundingDate && (
                        <Typography variant="body1">
                            <strong>Founded:</strong> {new Date(company.foundingDate).toLocaleDateString()}
                        </Typography>
                    )}
                </Paper>
                {company.evaluation && (
                    <Paper
                        sx={{
                            padding: 3,
                            mb: 3,
                            backgroundColor: '#ffffff',
                            border: '0.5px solid #DCDCDC',
                            borderRadius: '10px',
                            boxShadow: 'none',
                        }}
                    >
                        {/* Kopfzeile mit Button */}
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <Typography variant="h6" sx={{ color: '#386FA6' }}>
                                Evaluation
                            </Typography>
                            <IconButton
                                onClick={handleDownloadPdf}
                                disabled={isPdfLoading}
                                title="Download Report"
                            >
                                <PictureAsPdfIcon />
                            </IconButton>
                        </Box>

                        <Divider sx={{ borderColor: '#DCDCDC', marginBottom: 2 }} />
                        <EvaluationDisplay evaluation={company.evaluation} />
                    </Paper>
                )}
            </Box>
        </Box>
    );
};

export default CompanyProfile;
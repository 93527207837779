// src/components/public/Login.js
import React, { useState } from 'react';
import { Typography, Box, Grid, Paper, Modal, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import LoginForm from './LoginForm';
import BackgroundContainer from './BackgroundContainer';
import LogoAvatar from './LogoAvatar';
import {getAuth, sendEmailVerification, signOut} from 'firebase/auth';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [userNotVerified, setUserNotVerified] = useState(null);
    const [verificationMessage, setVerificationMessage] = useState('');
    const [verificationError, setVerificationError] = useState('');

    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setMessage(null);
            const userCredential = await login(email, password);
            const user = userCredential.user;

            // If the email is not verified, show the modal.
            if (!user.emailVerified) {
                setUserNotVerified(user);
                setModalOpen(true);
            } else {
                // Email is verified → redirect directly.
                navigate('/companies');
            }
        } catch (err) {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser && !currentUser.emailVerified) {
                setUserNotVerified(currentUser);
                setModalOpen(true);
            } else {
                setMessage('Login failed. Please try again.');
            }
        }
    };

    // Function to request a new verification email.
    // After the email is sent, a timer is started to log out the user in 3 seconds.
    const handleResendVerification = async () => {
        if (userNotVerified) {
            try {
                sendEmailVerification(userNotVerified)
                setVerificationMessage('Verification email sent. You will be logged out in 3 seconds. Please check your inbox.');
                setVerificationError('');
                setTimeout(() => {
                    handleLogout();
                }, 3000);
            } catch (err) {
                setVerificationError('Failed to send verification email. Please try again.');
                setVerificationMessage('');
            }
        }
    };

    // Function to log out the user.
    const handleLogout = async () => {
        const auth = getAuth();
        await signOut(auth);
        setModalOpen(false);
        navigate('/login');
    };

    const navigateToResetPassword = () => {
        navigate('/reset-password');
    };

    const navigateToRegister = () => {
        navigate('/register');
    };

    return (
        <Grid container component="main" sx={{ height: '98vh', margin: '4' }}>
            <BackgroundContainer />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={4} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'primary.dark',
                    }}
                >
                    <LogoAvatar />
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <LoginForm
                        handleSubmit={handleSubmit}
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        error={null}
                        message={message}
                        navigateToResetPassword={navigateToResetPassword}
                        navigateToRegister={navigateToRegister}
                    />
                </Box>
            </Grid>

            {/* Modal displayed when the email is not verified */}
            <Modal open={modalOpen} onClose={() => {}}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        outline: 'none',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Email Not Verified
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Your email address has not been verified. Please check your inbox for a verification email.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleResendVerification}
                        sx={{ mt: 2 }}
                    >
                        Resend Verification Email
                    </Button>
                    {verificationMessage && (
                        <Typography variant="body2" color="success.main" sx={{ mt: 1 }}>
                            {verificationMessage}
                        </Typography>
                    )}
                    {verificationError && (
                        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                            {verificationError}
                        </Typography>
                    )}
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleLogout}
                        sx={{ mt: 2 }}
                    >
                        Logout
                    </Button>
                </Box>
            </Modal>
        </Grid>
    );
};

export default Login;
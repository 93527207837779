// src/components/private/company/CompanyItem.js
import React, { useState } from 'react';
import {
    Box,
    Avatar,
    Typography,
    Button,
    Link as MuiLink,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Chip,
    IconButton
} from '@mui/material';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete'; // Neu: Import des Delete-Icons
import { useDeleteCompanyMutation } from '../../../api/companyApi';
import { useAlert } from '../../Alert';
import { useSelector } from "react-redux";
import { selectCurrentUserRoles } from "../../../redux/selectors";

const CompanyItem = ({ company }) => {
    const [showMoreDescription, setShowMoreDescription] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteCompany] = useDeleteCompanyMutation();
    const showAlert = useAlert();
    const roles = useSelector(selectCurrentUserRoles);

    const similarCompanies = company._company_similar_of_company_basic || [];

    const formatURL = (url) => {
        return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
    };
    const logoUrl = "https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=" + formatURL(company.URL_Text) + "&size=128";
    // const categories = company._company_diseasecategories_of_company_basi || [];

    // Erstelle die Chips, z. B. für Moonshot und DTX
    const chips = [];
    if (company.Is_Moonshot) {
        chips.push(<Chip key="moonshot" label="Moonshot" color="primary" size="small" sx={{ ml: 1 }} />);
    }
    if (company.Is_Dtx) {
        chips.push(<Chip key="dtx" label="DTX" color="secondary" size="small" sx={{ ml: 1 }} />);
    }

    const handleShowMoreDescription = () => {
        setShowMoreDescription(prev => !prev);
    };

    // Öffnet den Delete-Dialog
    const handleDeleteClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteCompany(company.id).unwrap();
            showAlert(`Company "${company.Company_name}" has been deleted.`, 'success');
        } catch (error) {
            showAlert(`Failed to delete company: ${error.message}`, 'error');
        }
        setOpenDialog(false);
    };


    return (
        <>
            <Box
                sx={{
                    p: 1,
                    // Die untere Abtrennlinie wurde entfernt
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                }}
            >
                {/* Header: Linke und rechte Spalte in einer Zeile */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Linke Spalte: Logo, Firmenname und Homepage-Link */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {logoUrl && (
                            <Avatar
                                src={logoUrl}
                                alt={company.Company_name}
                                sx={{ width: 32, height: 32, mr: 1 }}
                            />
                        )}
                        <MuiLink
                            component={Link}
                            target="_blank"
                            to={`/company/${company.Slug}`}
                            sx={{ textDecoration: 'none', mr: 1 }}
                        >
                            <Typography variant="body1" sx={{ fontSize: '0.9rem', fontWeight: 500 }}>
                                {company.Company_name}
                            </Typography>
                        </MuiLink>
                        <Tooltip title="Firmenhomepage öffnen">
                            <MuiLink
                                href={formatURL(company.URL_Text)}
                                target="_blank"
                                rel="noopener"
                                sx={{ display: 'flex' }}
                            >
                                <OpenInNewIcon fontSize="small" />
                            </MuiLink>
                        </Tooltip>
                    </Box>
                    {/* Rechte Spalte: Chips und ggf. Delete Button */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {chips.length > 0 && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {chips}
                            </Box>
                        )}
                        {(roles.includes('ADMIN') || roles.includes('SUPER_ADMIN')) && (
                            <IconButton
                                size="small"
                                color="secondary"
                                onClick={handleDeleteClick}
                                sx={{ ml: 1 }}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                </Box>

                {/* Beschreibung */}
                <Box>
                    <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                        {company.Company_description &&
                            (showMoreDescription
                                ? company.Company_description
                                : `${company.Company_description.substring(0, 100)}...`)}
                        {company.Company_description &&
                            company.Company_description.length > 100 && (
                                <Button
                                    size="small"
                                    onClick={handleShowMoreDescription}
                                    sx={{
                                        textTransform: 'none',
                                        p: 0,
                                        minWidth: 'auto',
                                        ml: 0.5,
                                    }}
                                >
                                    {showMoreDescription ? 'Weniger' : 'Mehr'}
                                </Button>
                            )}
                    </Typography>
                </Box>

                {/* Ähnliche Firmen – nur anzeigen, wenn vorhanden */}
                {similarCompanies.length > 0 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mt: 0.5 }}>
                        {similarCompanies.map((similar) => (
                            <Box key={similar.id} sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <Avatar
                                    src={similar.Logo_Image?.url || ''}
                                    alt={similar.similar_to_company_name}
                                    sx={{ width: 24, height: 24 }}
                                />
                                <MuiLink
                                    component={Link}
                                    to={`/company/${similar.Slug}`}
                                    sx={{ textDecoration: 'none' }}
                                >
                                    <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                                        {similar.similar_to_company_name}{' '}
                                        <strong>{similar.Similarity_value.toFixed(2)}</strong>
                                    </Typography>
                                </MuiLink>
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>

            {/* Delete-Bestätigungsdialog (nur für ADMIN/SUPER_ADMIN) */}
            {(roles.includes('ADMIN') || roles.includes('SUPER_ADMIN')) && (
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>Bestätigen</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Möchten Sie die Firma "{company.Company_name}" wirklich löschen?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={handleConfirmDelete} color="secondary">
                            Löschen
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default CompanyItem;